@use 'styles/variables' as *;

.chartContainer {
  flex: 1;
  margin-top: -20px;
}

.yAxis {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 8px;
  margin-bottom: 20px;
  & p {
    font-size: 10px;
    font-weight: 500;
    color: $textDisabled;
  }
}

.areaChartTooltip {
  display: flex;
  flex-direction: column;
  box-shadow: none;
  border-radius: 12px;
  background: transparent;
  & small {
    padding: 0.5rem;
    border: 1px solid $divider;
    background-color: $bgColor;
    color: $textSecondary;
    & b {
      color: $textPrimary;
    }
    &:first-child {
      border-radius: 12px 12px 0 0;
    }
    &:last-child {
      border-radius: 0 0 12px 12px;
    }
  }
}