@use 'styles/variables' as *;

.modalWrapper {
  margin: 0px;
  padding: 0px;
  max-width: 500px;
  width: calc(100% - 23px);
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 20px;
  transform: translate(-50%, -50%);
  outline: none;
  background-color: $bgPalette;
  @media screen and (min-width: 1280px) {
    margin-left: 8% !important;
  }
  
}